import React from "react";

import "./index.scss";

import IntroSection from "../IntroSection";
import CategoriesSection from "../CategoriesSection";
import CertificationSection from "../CertificationSection";
import ProductsSection from "../ProductsSection";
import MainHeader from "../MainHeader";
import AboutSection from "../AboutSection";
import ShownProductContext from "../../contexts/ShownProductContext";
import ProductPage from "../ProductPage";
import ContactSection from "../ContactSection";


// Popup component
// class Popup extends React.Component {
//   render() {
//     return (
//       <div className="popup">
//         <div className="popup-inner">
//           <button className="close-btn" onClick={this.props.onClose}>
//             Close
//           </button>
//           <img src="./Invitation.JPG" alt="invitation"></img>
//         </div>
//       </div>
//     );
//   }
// }
class App extends React.Component {

    state = {
        shownProduct: null,
        showProduct: product => {
            this.setState({ shownProduct: product });
            window.history.pushState({ shownProduct: product }, document.title);
        },
        // isPopupOpen: false
    }
  //   // Function to open the popup
  // openPopup = () => {
  //   this.setState({ isPopupOpen: true });
  // };

  // // Function to close the popup
  // closePopup = () => {
  //   this.setState({ isPopupOpen: false });
  // };


    componentDidMount() {
        window.history.replaceState({ shownProduct: this.state.shownProduct }, document.title);

        window.addEventListener("popstate", event => {
            this.state.showProduct(event.state.shownProduct);
        })
         // Open the popup automatically when the component mounts
    // this.openPopup();
        
    }

    render() {
        return (
            <ShownProductContext.Provider value={this.state}>
              {/* Render the Popup component conditionally based on state */}
        {/* {this.state.isPopupOpen && <Popup onClose={this.closePopup} />} */}
                <MainHeader isBackHeader={this.state.shownProduct !== null} onBack={() => this.state.showProduct(null)} />
                <IntroSection />
                <CategoriesSection />
                <ProductsSection />
                <AboutSection />
                <CertificationSection />
                <ContactSection />
                <ProductPage productId={this.state.shownProduct} />
                 
        

            </ShownProductContext.Provider>
        );
    }
}

export default App;