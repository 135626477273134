import React from "react";
import PageSection from "../PageSection";

import "./index.scss";
import ProductFilters from "./ProductFilters.js";
import ProductList from "./ProductList.js";
import ProductPage from "../ProductPage";

import { filters, defaultFilter, partialProducts } from "../../data/";

class ProductsSection extends React.Component{

    state = {
        selectedFilter: defaultFilter
    }

    handleSelectedFilterChange = selectedFilter => {
        this.setState({selectedFilter})
        window.scroll({
            top: document.getElementById("products").offsetTop - 56,
            behavior: "smooth"
        });
    }

    render(){
        return (
            <PageSection id="products" heading="Products">
                <ProductFilters filters={filters} selectedFilter={this.state.selectedFilter} onSelectedFilterChange={this.handleSelectedFilterChange}/>
                <ProductList products={partialProducts} selectedFilter={this.state.selectedFilter}/>
                <ProductPage/>
            </PageSection>
        );
    }
}
export default ProductsSection;