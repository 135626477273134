import React from "react";
import OverflowImage from "../OverflowImage";
import Isotope from "isotope-layout";
import ShownProductContext from "../../contexts/ShownProductContext";

class ProductList extends React.Component{

    constructor(props, ...args){
        super(props, ...args);
        this.products = props.products;
    }

    setUpIsotope(){
        this.isotope = new Isotope(this.productsElem, {
            itemSelector: ".product",
            layoutMode: "fitRows",
        })
        /*this.shuffle = new Shuffle(this.productsElem, {
            itemSelector: ".product"
        })*/
    }

    updateIsotope(){
        this.isotope.arrange({
            filter: ".filter-" + this.props.selectedFilter.id
        });

        this.isotope.getFilteredItemElements()
        .forEach(product => {
            this.products.find(p => p.id === product.dataset.id).imgRef.load();
        })

        /*let selectedFilter = this.props.selectedFilter.id;
        this.shuffle.filter(selectedFilter);

        [...this.productsElem.querySelectorAll(".product")]
        .filter(product => JSON.parse(product.dataset.groups).includes(selectedFilter))
        .forEach(product => {
            console.log(product);
            this.products.find(p => p.id === product.dataset.id).imgRef.load();
        })*/
    }

    componentDidMount(){
        this.setUpIsotope();
        this.updateIsotope();
    }

    componentDidUpdate() {
        this.updateIsotope();
    }

    handleOnLoad(product){
        product.elemRef.classList.remove("skeleton-loading");
        setTimeout(() => this.isotope.layout());
    }

    render(){
        return (
            <ShownProductContext.Consumer>{({showProduct}) => (
                <div className="product-list" ref={elem => this.productsElem = elem}>
                    {this.products.map(
                        product => (
                            <div className={["product", "skeleton-loading", ...product.filters.map(x => "filter-" + x)].join(" ")}
                                onClick={() => showProduct(product.id)}
                                key={product.id}
                                ref={ref => product.elemRef = ref}
                                data-id={product.id}>

                                <OverflowImage
                                    ref={ref => product.imgRef = ref}
                                    className="skeleton"
                                    img={product.img}
                                    lazyLoad={true}
                                    onLoad={() => this.handleOnLoad(product)}/>

                                <div className="name">{product.name}</div>
                            </div>
                        )
                    )}
                </div>
            )}</ShownProductContext.Consumer>
        );
    }
}
export default ProductList;