import React from "react";
import classNames from "classnames";

class ProductFilters extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            selectedFilter: props.selectedFilter
        }
    }

    setUpFilterSticky(){
        window.addEventListener("scroll", () => {
            let mainHeaderHeight = document.querySelector("#main-header").getBoundingClientRect().height
            let filterElemTop = this.filtersElem.getBoundingClientRect().top

            if(filterElemTop <= mainHeaderHeight){
                this.filtersElem.classList.add("is-stuck");
            }else{
                this.filtersElem.classList.remove("is-stuck");
            }
        })
    }

    selectFitler(filter){
        this.setState({
            selectedFilter: filter
        });
        this.props.onSelectedFilterChange(filter);
    }

    componentDidMount(){
        this.setUpFilterSticky();
    }

    render(){
        return (
            <div className="product-filters" ref={elem => this.filtersElem = elem}>
                {this.props.filters.map(filter =>
                    <div
                        className={classNames("filter", {"is-selected": filter === this.state.selectedFilter})}
                        onClick={() => this.selectFitler(filter)}
                        data-filter-id={filter.id}
                        key={filter.id}>
                            {filter.name}
                    </div>
                )}
            </div>
        )
    }
}
export default ProductFilters;