import React from "react";
import classNames from "classnames";

import "./index.scss";

class OverflowImage extends React.Component{

    fireOnLoad(){
        if(!this.onLoadFired){
            this.props.onLoad && this.props.onLoad();
            this.onLoadFired = true;
        }
    }

    load(){
        this.imgElem.setAttribute("src", this.imgElem.dataset.src);

        this.onLoadFired = false;
        this.imgElem.complete && this.fireOnLoad();
        this.imgElem.addEventListener("load", () => this.fireOnLoad())
    }

    render(){
        const makeKebab = str => str.replace(/([a-z0-9])([A-Z])/g, "$1-$2").toLowerCase();
        
        return (
            <div className={classNames("overflow-image", this.props.className)} style={
                ["width","height","x","y","relativeScale"].reduce(
                    (style, key) => ({...style, ["--" + makeKebab(key)]: this.props.img[key]}),
                    {}
                )
            }>
                <img
                    ref={elem => this.imgElem = elem}
                    src={!this.props.lazyLoad ? this.props.img.src : undefined}
                    data-src={this.props.lazyLoad ? this.props.img.src : undefined}
                    alt={this.props.alt}/>
            </div>
        )
    }    
}
export default OverflowImage;