import React from "react";

const Logo = props => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 126.113 80">
        <style>{`
            .background-fill{
                fill: var(--background-fill, #000000);
            }
            .foreground-fill{
                fill: var(--foreground-fill, #FFFFFF)
            }
        `}</style>
        <rect className="right-box background-fill" x="45.72" width="80.394" height="80"/>
        <rect className="left-box background-fill" width="42.901" height="80"/>
        <path className="meditech-text foreground-fill" d="M52.142,13.126h1.451l1.822,11.633
            c0.029,0.214,0.143,1.138,0.333,2.766c0.062,0.66,0.122,1.181,0.172,1.562c0.054-0.655,0.134-1.343,0.229-2.065
            c0.103-0.723,0.215-1.473,0.345-2.263l1.945-11.633h1.403l2.189,17.956h-1.498l-1.222-11.759c0-0.046-0.002-0.135-0.021-0.255
            c-0.124-1.335-0.221-2.549-0.296-3.64c-0.097,1.104-0.188,2.002-0.269,2.691c-0.083,0.688-0.167,1.297-0.26,1.821l-1.868,11.143
            h-1.416l-1.784-11.587c-0.009-0.054-0.024-0.15-0.05-0.28c-0.227-1.453-0.386-2.713-0.479-3.787c-0.05,0.55-0.104,1.251-0.171,2.103
            c-0.097,1.256-0.16,1.96-0.185,2.128l-1.302,11.423h-1.49L52.142,13.126L52.142,13.126z M64.344,13.126h5.646v1.512h-3.997v6.469
            h3.945v1.465h-3.945v6.998h4.117v1.512h-5.767V13.126L64.344,13.126z M74.687,14.613V29.57h0.317c1.453,0,2.46-0.496,3.021-1.491
            c0.562-0.99,0.843-2.887,0.843-5.691c0-3.14-0.268-5.223-0.811-6.243c-0.54-1.02-1.528-1.532-2.95-1.532H74.687L74.687,14.613z
            M73.036,13.126h2.044c2.041,0,3.464,0.639,4.266,1.916c0.804,1.271,1.204,3.647,1.204,7.122c0,3.305-0.414,5.617-1.241,6.936
            c-0.827,1.322-2.255,1.981-4.277,1.981h-1.995V13.126L73.036,13.126z M83.291,31.082V13.126h1.65v17.956H83.291L83.291,31.082z
            M89.697,14.593h-2.803v-1.466h7.231v1.466h-2.79v16.489h-1.639V14.593L89.697,14.593z M96.166,13.126h5.646v1.512h-3.996v6.469
            h3.946v1.465h-3.946v6.998h4.118v1.512h-5.769V13.126L96.166,13.126z M111.105,30.598c-0.343,0.2-0.712,0.349-1.112,0.45
            c-0.396,0.104-0.805,0.157-1.22,0.157c-1.625,0-2.83-0.736-3.611-2.208c-0.784-1.47-1.175-3.725-1.175-6.759
            c0-3.022,0.42-5.314,1.256-6.873c0.835-1.556,2.069-2.336,3.701-2.336c0.408,0,0.797,0.042,1.163,0.12
            c0.361,0.085,0.714,0.211,1.049,0.384v1.759c-0.386-0.257-0.738-0.441-1.063-0.556c-0.325-0.112-0.645-0.172-0.964-0.172
            c-1.184,0-2.049,0.606-2.603,1.809c-0.556,1.207-0.832,3.086-0.832,5.643c0,2.783,0.256,4.747,0.769,5.899
            c0.513,1.152,1.365,1.729,2.553,1.729c0.248,0,0.483-0.024,0.71-0.076c0.223-0.045,0.443-0.121,0.654-0.218
            c0.124-0.061,0.248-0.127,0.368-0.207c0.126-0.077,0.244-0.16,0.356-0.252V30.598L111.105,30.598z M113.151,13.126h1.65v7.233h4.277
            v-7.233h1.637v17.956h-1.637v-9.294h-4.277v9.294h-1.65V13.126z"/>
        <path className="bird-text foreground-fill" d="M37.244,73.95H5.295v-8.267
            c0-1.97,0.448-3.688,1.345-5.141c0.899-1.462,2.335-2.184,4.313-2.184h4.434c2.244,0,3.826,1.362,4.746,4.1
            c0.715-2.883,2.251-4.328,4.608-4.328h6.198c2.106,0,3.689,0.693,4.729,2.062c1.051,1.377,1.575,3.011,1.575,4.913V73.95
            L37.244,73.95z M16.521,64.978h-5.43c-1.118,0-1.674,0.652-1.674,1.954h8.8C18.216,65.63,17.654,64.978,16.521,64.978L16.521,64.978
            z M31.45,64.978h-8.086c-1.118,0-1.673,0.652-1.673,1.954h11.454C33.146,65.63,32.583,64.978,31.45,64.978L31.45,64.978z
            M37.291,30.243v7.021c-0.906,0.759-3.477,1.132-7.712,1.132h-0.047l-1.916-0.038c-2.654-0.046-4.016-0.07-4.076-0.07
            c-1.081,0-1.627,0.724-1.627,2.183h15.331v7.02H5.295v-8.67c0-1.976,0.448-3.696,1.361-5.155c0.905-1.468,2.334-2.199,4.297-2.199
            h3.833c2.479,0,4.16,1.453,5.035,4.349c0.684-2.911,2.441-4.372,5.278-4.372l3.499,0.114C33.707,31.559,36.606,31.118,37.291,30.243
            L37.291,30.243z M16.346,38.289H11.09c-1.118,0-1.674,0.724-1.674,2.183h8.625C18.041,39.012,17.472,38.289,16.346,38.289
            L16.346,38.289z M37.244,29.377H5.295v-8.733c0-1.9,0.516-3.597,1.543-5.079c1.034-1.492,2.625-2.23,4.762-2.23h19.34
            c1.992,0,3.536,0.694,4.646,2.086c1.104,1.383,1.658,3.108,1.658,5.178V29.377L37.244,29.377z M31.45,20.402H11.09
            c-1.118,0-1.674,0.645-1.674,1.954h23.729C33.146,21.047,32.583,20.402,31.45,20.402z"/>
        <polygon className="bird foreground-fill" points="2.962,49.379 40.692,49.379 62.261,63.714 
            64.001,63.714 85.74,49.379 123.469,49.379 116.295,56.46 89.303,56.46 77.621,65.545 81.274,65.498 84.795,67.232 84.795,74.5 
            83.013,72.717 68.613,72.717 64.001,76.327 44.212,76.327 53.21,70.982 37.175,56.46 10.135,56.46 "/>
    </svg>
);
export default Logo;