import React from "react";

import "./index.scss";

class PageSection extends React.Component{

    updateLayoutCssProps(){
        let wrapperElemRect = this.sectionElem.querySelector(".page-section-wrapper").getBoundingClientRect();
        let startElemRect = this.headingElem.getBoundingClientRect();
        Object.entries({
            "--page-section-start-offset-y": (startElemRect.y - wrapperElemRect.y) + "px",
            "--page-section-start-end-offset-x": (startElemRect.x + startElemRect.width) + "px"
        })
        .map(([prop,val]) => this.sectionElem.style.setProperty(prop,val))
    }

    componentDidMount(){
        setTimeout(() => {
            this.updateLayoutCssProps();
            window.addEventListener("resize", () => this.updateLayoutCssProps());
        });
    }
    componentDidUpdate(){ this.updateLayoutCssProps(); }

    render(){
        return (
            <div ref={elem => this.sectionElem = elem} className="page-section" {...this.props}>
                <div className="page-section-wrapper">
                    <h1 ref={elem => this.headingElem = elem} className="page-section-heading">{this.props.heading}</h1>
                    <div className="page-section-content">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}
export default PageSection;