import React from "react";
import classNames from "classnames";

import { filters, partialProducts, getProduct } from "../../data/";
import OverflowImage from "../OverflowImage";

import "./index.scss";
import "./product-content.css";

class ProductPage extends React.Component{
    
    state = {
        partial: null,
        contentHTML: null
    }

    componentDidUpdate(prevProps){
        if(this.props.productId !== prevProps.productId && this.props.productId){
            this.setState({
                partial: partialProducts.find(p => p.id === this.props.productId)
            });
            this.setState({contentHTML: null});
            this.productPageElem && this.productPageElem.scrollTo(0,0);
            
            getProduct(this.props.productId).then(data => {
                this.setState({contentHTML: data.contentHTML})
            })
        }

        if(this.props.productId){
            document.body.classList.add("is-showing-product");
        }else{
            document.body.classList.remove("is-showing-product");
        }
    }

    render(){
        return (
            <div ref={elem => this.productPageElem = elem} className={classNames("product-page", {"is-shown": this.props.productId})}>{this.state.partial ? <>
                <h1 className="product-name">{this.state.partial.name}</h1>
                <div className="product-category">{
                    this.state.partial.filters.map(fId => filters.find(f => f.id === fId)).filter(f => f.isCategory && !f.isProduct).map((filter, i, thisFilters) => 
                        <React.Fragment key={filter.id}>
                            {/* eslint-disable-next-line */}
                            <a>{filter.name}</a>
                            {i === thisFilters.length - 1 ? undefined : ", "}
                        </React.Fragment>
                    )
                }</div>
                <div className={[
                    "page-content",
                    this.state.partial.hasFullpageContent ? "has-fullpage-content" : false
                ].filter(Boolean).join(" ")}>
                    <div className="product-image">
                        <OverflowImage img={{...this.state.partial.img, relativeScale: 1}}/>
                    </div>
                    {this.state.contentHTML ? (
                        <div className="product-content" dangerouslySetInnerHTML={{__html: this.state.contentHTML}}/>
                    ) : (
                        <div className="product-content skeleton-loading">
                            <div className="content-part"style={{width: "100%"}}>
                                <div className="skeleton" style={{height: "5.75em", marginBottom: "0.5em"}}></div>
                                <div className="skeleton" style={{height: "2.75em", marginBottom: "0.5em"}}></div>
                                <div className="skeleton" style={{height: "1.75em", marginBottom: "0.5em"}}></div>
                                <div className="skeleton" style={{height: "0.75em", marginBottom: "0.5em"}}></div>
                                <div className="skeleton" style={{height: "0.75em", marginBottom: "0.5em"}}></div>
                                <div className="skeleton" style={{width: "75%", height: "0.75em", marginBottom: "0.5em"}}></div>
                            </div>
                        </div>
                    )}
                </div>
            </> : undefined}</div>
        );
    }
}
export default ProductPage;