import React from "react";
import "./index.scss";

const IntroSection = () => (
    <div id="intro">
        <div className="intro-content">
            <h2>Tested, Reliable &amp; Modern</h2>
            <h1>Intensive Care Equipments <br/>for Newborns</h1>
            <h2>to reduce mortality rates</h2>
        </div>
        <div className="overlay-normal"></div>
        <div className="overlay-color"></div>
    </div>
);
export default IntroSection;