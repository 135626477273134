import React from "react";
import PageSection from "../PageSection";
import { ReactComponent as RadiantHeatWarmersSvg } from "./svg/radiant-heat-warmers.svg";
import { ReactComponent as NeonatalResuscitationUnitsSvg } from "./svg/neonatal-resuscitation-units.svg";
import { ReactComponent as PhototherapySystemsSvg } from "./svg/phototherapy-systems.svg";
import { ReactComponent as ContinuousPositiveAirwayPressureSvg } from "./svg/continuous-positive-airway-pressure.svg";
import { ReactComponent as ManualResuscitationUnitsSvg } from "./svg/manual-resuscitation-units.svg";
import { ReactComponent as WeighingScalesSvg } from "./svg/weighing-scales.svg";
import { ReactComponent as OxygenHoodSvg } from "./svg/oxygen-hood.svg";
import { ReactComponent as InfusionPumpSvg } from "./svg/infusion-pump.svg";
import { ReactComponent as IncubatorSvg } from "./svg/incubator.svg";
import { ReactComponent as DisinfectantSvg } from "./svg/disinfectant.svg";
import { ReactComponent as PatientMonitorsSvg } from "./svg/patient-monitors.svg";
import { ReactComponent as LaryngoscopeSvg } from "./svg/laryngoscope.svg";

import "./index.scss";

class CategoriesSection extends React.Component{

    updateLayoutCssProps(){
        let sectionElem = this.pageSection.sectionElem;
        let startElem = 
            [...document.querySelectorAll(".category")][Number(
                getComputedStyle(sectionElem)
                .getPropertyValue("--top-right-category-index")
            )]
            .querySelector("path");

        let wrapperElemRect = sectionElem.querySelector(".page-section-wrapper").getBoundingClientRect();
        let startElemRect = startElem.getBoundingClientRect();

        Object.entries({
            "--page-section-start-offset-y": (startElemRect.y - wrapperElemRect.y) + "px",
            "--page-section-start-end-offset-x": (startElemRect.x + startElemRect.width) + "px",
        }).map(([prop,val]) => sectionElem.style.setProperty(prop,val))
    }

    componentDidMount(){
        setTimeout(() => {
            this.updateLayoutCssProps();
            window.addEventListener("resize", () => this.updateLayoutCssProps());
        })
    }
    componentDidUpdate(){ this.updateLayoutCssProps(); }

    selectFilter(id){
        document.querySelector(`[data-filter-id="${id}"]`).click();
    }

    render(){
        return (
            <PageSection ref={ref => this.pageSection = ref} id="product-categories" heading="">
                <div className="category" onClick={() => this.selectFilter("9fzbjt")}>
                    <RadiantHeatWarmersSvg className="category-icon"/>
                    <div className="category-name">Radiant Heat Warmers</div>
                </div>
                <div className="category" onClick={() => this.selectFilter("zhd2k2")}>
                    <NeonatalResuscitationUnitsSvg className="category-icon"/>
                    <div className="category-name">Neonatal Resuscitation Units</div>
                </div>
                <div className="category" onClick={() => this.selectFilter("iyqbtb")}>
                    <ContinuousPositiveAirwayPressureSvg className="category-icon"/>
                    <div className="category-name">Continuous +ve Airway Pressure</div>
                </div>
                <div className="category" onClick={() => this.selectFilter("n93gkp")}>
                    <PhototherapySystemsSvg className="category-icon"/>
                    <div className="category-name">Phototherapy systems</div>
                </div>
                <div className="category" onClick={() => this.selectFilter("o90q5m")}>
                    <ManualResuscitationUnitsSvg className="category-icon"/>
                    <div className="category-name">Manual Resuscitation Units</div>
                </div>
                <div className="category" onClick={() => this.selectFilter("tkwn5v")}>
                    <WeighingScalesSvg className="category-icon"/>
                    <div className="category-name">Weighing Scales</div>
                </div>
                <div className="category" onClick={() => this.selectFilter("6hzez4")}>
                    <OxygenHoodSvg className="category-icon"/>
                    <div className="category-name">Oxygen Hood</div>
                </div>
                <div className="category" onClick={() => this.selectFilter("ug0j0m")}>
                    <InfusionPumpSvg className="category-icon"/>
                    <div className="category-name">Infusion Pumps</div>
                </div>
                <div className="category" onClick={() => this.selectFilter("5y9626")}>
                    <IncubatorSvg className="category-icon"/>
                    <div className="category-name">Incubator</div>
                </div>
                <div className="category" onClick={() => this.selectFilter("vrsmef")}>
                    <DisinfectantSvg className="category-icon"/>
                    <div className="category-name">Disinfectant</div>
                </div>
				<div className="category" onClick={() => this.selectFilter("pva89i")}>
                    <PatientMonitorsSvg className="category-icon"/>
                    <div className="category-name">Patient Monitors</div>
                </div>
                <div className="category" onClick={() => this.selectFilter("zsnn8p")}>
                    <LaryngoscopeSvg className="category-icon"/>
                    <div className="category-name">Video Laryngoscope</div>
                </div>
            </PageSection>
        )
    }
}
export default CategoriesSection;