import React from "react";
import PageSection from "../PageSection";

import "./index.scss";

const AboutSection = () => (
    <PageSection heading="About us" id="about">
        <p>Bird Meditech was founded in 2003 with the vision to provide international quality Neonatal Products at affordable prices. Founded by two young entrepreneurs in Mumbai, who set up an ISO 13485 standard compliant manufacturing facility. The main products of the company are:  Infant Radiant Warmers, Neonatal Phototherapy Equipment (CFL &amp; LED), Electronic Weighing Machines, CPAP machines, Manual resuscitation bags etc.</p>
        <p>From the beginning, the Company launched only products that are ready for sale in the World markets and each product is stringently crafted to meet International Standards and is CE certified.  The Company firmly believes that the Indian Customer deserves no less than the best available in the world, and each the products carry that Assurance &amp; Guarantee”.</p>
        <p>After Quality and reliability, a large emphasis and premium is placed on after-sales-service. “WE WILL NOT SELL WHAT WE CANNOT SERVICE”. This has created a Unique Brand Loyalty which brings to the Company 60% of their sales, constituting mainly repeat or referred orders.</p>
        <p>Bird Meditech has successfully participated in the Government initiative for expansion of the NEW BORN CARE setups through NRHM and was selected by UNICEF, PHSC, NRHM Odissha, MPLUN, etc as their approved vendor and have also supplied to various other prestigious projects in the country as well in the SAARC region.</p>
        <p>In the process of innovating state-of-the-art technology to facilitate new born care, Bird Meditech was the one of the first in the country with some innovations including on board calibration technology in radiant warmer, voice messaging technology in radiant warmers, and radiant warmer with international quality at affordable prices.</p>
        <p>Bird Meditech has constantly endeavored to associate and train skilled distributor network all over India in order to ensure the availability of quality technology and service at affordable price to every customer.</p>
        <p>Our mantra has been to “provide solutions to create desired outcomes using only freely available resources in the country”. This has helped us provide cutting edge products, easily serviceable even by technicians, at  price one third or  lesser of the imported counterparts, without compromise on the outcomes from our machines.</p>
    </PageSection>
)
export default AboutSection;