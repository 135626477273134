import React from "react";
import Logo from "../Logo";
import classNames from "classnames";

import "./index.scss";

class MainHeader extends React.Component{

    state = {
        isScrolled: false
    }
    

    componentDidMount(){
        window.addEventListener("scroll", () => {
            this.setState({
                isScrolled: window.scrollY > 0
            });
        });
    }

    scrollTo(id){
        window.scroll({
            top: document.getElementById(id).offsetTop - 56,
            behavior: "smooth"
        })
    }

    handleLinkClick = event => {
        event.preventDefault();
        document.getElementById("isMainHeaderMenuOpen").checked = false;
        this.scrollTo(
            event.target.getAttribute("href").slice(1)
        );
    }


    render(){
        return (
            <header
                id="main-header"
                ref={elem => this.mainHeaderElem = elem}
                className={classNames({
                    "is-scrolled": this.state.isScrolled,
                    "is-back-header": this.props.isBackHeader,
                })}>
                    <svg onClick={this.props.onBack} className="back-btn" xmlns="http://www.w3.org/2000/svg" viewBox="4 4 16 16">
                        <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
                    </svg>
                    <div className="logo">
                        <Logo className="logo-full"/>
                    </div>
                    <input type="checkbox" className="menu-checkbox" id="isMainHeaderMenuOpen"/>
                    <label className="menu-btn" htmlFor="isMainHeaderMenuOpen"></label>
                    <nav>
                        <a onClick={this.handleLinkClick} href="#products">Products</a>
                        <a onClick={this.handleLinkClick} href="#about">About</a>
                        <a onClick={this.handleLinkClick} href="#certification">Certification</a>
                        <a onClick={this.handleLinkClick} href="#contact">Contact</a>
                    </nav>
            </header>
        );
    }
};
export default MainHeader;